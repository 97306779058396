import styles from './DownloadButton.module.css'
import { useState } from 'react';

function DownloadButton(props) {
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);

    return (
        <div style={{ position: 'relative', height: 40, width: props.extension }}>
            <div className={styles.buttonWrapper} style={!isMouseOverButton ? { width: props.width } : { filter: "drop-shadow(4px 4px 4px rgba(89, 116, 130, 0.25))", width: props.extension }} onMouseOver={() => setIsMouseOverButton(true)}
                onMouseLeave={() => setIsMouseOverButton(false)}>
                <div className={styles.buttonText} style={{ display: 'flex', marginLeft: 30 }}>
                    {props.text}
                </div>
                <div style={{ flex: 1 }} />
                <div style={{ display: 'flex', marginRight: 30 }}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17.5409C0 17.9054 0.142807 18.2519 0.334041 18.4419C0.521592 18.6288 0.828755 18.8769 1.18737 18.8769H17.8126C18.3692 18.8769 19 18.3007 19 17.5409V11.9746H16.3568V16.3163H2.64322V11.9746H0V17.5409Z" fill="#0092DD" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66131 0.382881V6.83988C6.96214 6.83988 5.88647 6.77004 5.24805 6.914C5.24805 7.22954 5.38088 7.26096 5.66927 7.61938C5.84011 7.83207 6.03252 8.03103 6.21103 8.24483L8.89713 11.3947C9.51888 12.0712 9.56802 12.2515 10.2751 11.4129C10.4566 11.1973 10.6008 11.0121 10.7804 10.7891L13.2925 7.61917C13.3759 7.51136 13.4448 7.41591 13.5386 7.30095C13.6478 7.16686 13.987 6.72851 13.4839 6.72851H11.4154V0.419944C11.4154 0.243206 11.2979 0.126641 11.1175 0.123047H11.1089H7.96776H7.95943C7.78719 0.125736 7.66131 0.207927 7.66131 0.382881Z" fill="#0092DD" />
                    </svg>

                </div>
            </div>
        </div>
    );
}

export default DownloadButton;
