import styles from './DownloadButtonOS.module.css'
import { useState } from 'react';
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";
import DownloadButton from './../DownloadButton/DownloadButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function DownloadButtonOS(props) {
    const address = useStore(addressStore);
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                <LazyLoadImage
                    src={props.icon}
                    effect="blur"
                />
            </div>
            <div>
                <div className={styles.productTextHeader} style={{ textDecoration: 'none' }}>
                    {props.text}
                </div>
                <div>
                    <div style={{ marginTop: 5, color: "#201644" }}>
                        <a href={address + "/uploads/"+props.link} target="_blank">
                            <DownloadButton width={props.width} extension={props.extension} text={props.text2} />
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadButtonOS;
