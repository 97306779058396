import { Element, Operation, Transforms } from "slate";
import { nanoid } from "nanoid";
import { useSlate } from "slate-react";

export const makeId = () => nanoid(16);

export const withNodeId = (editor) => {
  const { apply } = editor;

  const assignIdRecursively = (node) => {
    if (Element.isElement(node)) {
      node.id = makeId
    ();
      node.children.forEach(assignIdRecursively);
    }
  };

  editor.apply = (operation) => {
    let trueOperation = JSON.parse(JSON.stringify(operation))
    if (operation.type === "insert_node") {
        assignIdRecursively(trueOperation.node);
        return apply(trueOperation);
    }

    if (operation.type === "split_node") {
        operation.properties.id = makeId
      ();
        return apply(operation);
    }

    return apply(operation);
  };

  return editor;
};
