import styles from './BlueButton.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from "react";
import buttonIcon from '../../../Images/Regular_size/buttonIcon.svg';
function BlueButton(props) {
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);

    return (
        <div>
            <div className={styles.buttonWrapper} style={!isMouseOverButton ? { background: "#0092DD",width: props.width } : { background: "linear-gradient(166.51deg, rgba(255, 255, 255, 0) 9.67%, rgba(254, 254, 254, 0.5) 227.74%), #0092DD", width: props.extension }} onMouseOver={() => setIsMouseOverButton(true)}
                onMouseLeave={() => setIsMouseOverButton(false)}>
                <div className={styles.buttonText} style={{ display: 'flex', marginLeft: 30}}>
                    {props.text}
                </div>
                <div style={{ flex: 1 }} />
                <div style={{ display: 'flex', marginRight: 30 }}>
                    <LazyLoadImage
                        src={buttonIcon}
                    />
                </div>
            </div>
        </div>
    );
}

export default BlueButton;
