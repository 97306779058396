// import Customers from './../Customers/Customers';
// import Order from './../Customers/Order/Order';
// import Price from './../Customers/Price/Price';
import Footer from '../Footer/Footer';
import Header from './../Header/Header';
import { useEffect, useLayoutEffect, useRef, useState, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import useResizeObserver from "@react-hook/resize-observer"
import Order804 from '../Order804/Order804';
import Customers from '../Customers/Customers';
import ProductBuilder from './../ProductBuilder/ProductBuilder';
import NewsView from '../NewsView/NewsView';
import mail2 from '../../Images/Regular_size/mail2.svg';
import CommercialOffer from './../CommonComponents/CommercialOffer/CommercialOffer';
import styles from './PageWrapper.module.css'
const MainPage = lazy(() => import("./../MainPage/MainPage"));
const News = lazy(() => import("./../News/News"));
const Products = lazy(() => import("./../Products/Products"));
const About = lazy(() => import("./../About/About"));
const Contact = lazy(() => import("./../Contact/Contact"));
const Moderation = lazy(() => import("../Moderation/Moderation"));
const PageBuilder = lazy(() => import("./../PageBuilder/PageBuilder"));
const ProductViewer = lazy(() => import("./../ProductViewer/ProductViewer"));
const NewsBuilder = lazy(() => import("./../NewsBuilder/NewsBuilder"));
const NewsViewer = lazy(() => import("./../NewsViewer/NewsViewer"));





function PageWrapper() {

    const [isKP, setIsKP] = useState(false)
    const location = useLocation();
    const [current, setCurrent] = useState("-1");
    const [old, setOld] = useState("-1");
    useEffect(() => {
        setOld(current)
        setCurrent(location.pathname.split('/')[1] === '' ? "main" : location.pathname.substring(location.pathname.indexOf("/") + 1))
        //console.log(location.pathname.split('/')[1] === '' ? "main" : location.pathname.substring(location.pathname.indexOf("/") + 1))
        setTimeout(function () {
            setOld("-1")
        }, 350);
    }, [location.pathname.split('/')[1]])
    const target = useRef(null)
    const size = useSize(target)
    const MainRef = useRef(null)
    const MainSize = useSize(MainRef)
    const NewsRef = useRef(null)
    const NewsSize = useSize(NewsRef)
    const NewsViewerRef = useRef(null)
    const NewsViewerSize = useSize(NewsViewerRef)
    const NewsViewRef = useRef(null)
    const NewsViewSize = useSize(NewsViewRef)
    const ProductsRef = useRef(null)
    const ProductsSize = useSize(ProductsRef)
    const AboutRef = useRef(null)
    const AboutSize = useSize(AboutRef)
    const ContactRef = useRef(null)
    const ContactSize = useSize(ContactRef)
    const ModerationRef = useRef(null)
    const ModerationSize = useSize(ModerationRef)
    const PageBuilderRef = useRef(null)
    const PageBuilderSize = useSize(PageBuilderRef)
    const ProductBuilderRef = useRef(null)
    const ProductBuilderSize = useSize(ProductBuilderRef)
    const NewsBuilderRef = useRef(null)
    const NewsBuilderSize = useSize(NewsBuilderRef)
    const ProductViewerRef = useRef(null)
    const ProductViewerSize = useSize(ProductViewerRef)
    const Order804Ref = useRef(null)
    const Order804Size = useSize(Order804Ref)
    const CustomersRef = useRef(null)
    const CustomersSize = useSize(CustomersRef)
    const FooterRef = useRef(null)
    const FooterSize = useSize(FooterRef)


    function getHeight() {
        if ((current === "main" || old === "main") && MainSize?.["height"]) {
            return MainSize?.["height"]
        } else if ((current === "news" || old === "news") && NewsSize?.["height"]) {
            return NewsSize?.["height"]
        } else if ((current.includes("news_view") || old.includes("news_view")) && NewsViewerSize?.["height"]) {
            return NewsViewerSize?.["height"]
        } else if ((current.includes("rotatorTest") || old.includes("rotatorTest")) && NewsViewSize?.["height"]) {
            return NewsViewSize?.["height"]
        } else if ((current === "products" || old === "products") && ProductsSize?.["height"]) {
            return ProductsSize?.["height"]
        } else if ((current === "about" || old === "about") && AboutSize?.["height"]) {
            return AboutSize?.["height"]
        } else if ((current === "contact" || old === "contact") && ContactSize?.["height"]) {
            return ContactSize?.["height"]
        } else if ((current === "moderation" || old === "moderation") && ModerationSize?.["height"]) {
            return ModerationSize?.["height"]
        } else if ((current === "page_builder" || old === "page_builder") && PageBuilderSize?.["height"]) {
            return PageBuilderSize?.["height"]
        } else if ((current.includes("product_builder") || old.includes("product_builder")) && ProductBuilderSize?.["height"]) {
            return ProductBuilderSize?.["height"]
        } else if ((current.includes("news_builder") || old.includes("news_builder")) && NewsBuilderSize?.["height"]) {
            return NewsBuilderSize?.["height"]
        } else if ((current.includes("product_page") || old.includes("product_page")) && ProductViewerSize?.["height"]) {
            return ProductViewerSize?.["height"]
        } else if ((current === "order804" || old === "order804") && Order804Size?.["height"]) {
            return Order804Size?.["height"]
        } else if ((current === "customers" || old === "customers") && CustomersSize?.["height"]) {
            return CustomersSize?.["height"]
        }
    }

    return (
        <div class="notranslate" translate="no" style={{ height: getHeight() + FooterSize?.["height"] + size?.["height"], overflow: 'hidden' }}>
            {(!isKP) &&
                <div className={styles.kpWrapper} onClick={() => setIsKP(true)}>
                    <img src={mail2} width={"100%"} />
                </div>
            }
            {(isKP) && <CommercialOffer exit={() => setIsKP(false)} />}
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <div style={{ background: "#F6F6F6", width: '100vw', height: '100vh', position: 'fixed', zIndex: -100 }} />
                <div ref={target} style={{ zIndex: 1000 }}> <Header /> </div>
                <div ref={MainRef}>
                    {(current === "main" || old === "main") && <div style={current === "main" ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute' }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <MainPage />
                        </Suspense>
                    </div>}
                </div>
                <div ref={NewsRef}>
                    {(current === "news" || old === "news") && <div style={current === "news" ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <News />
                        </Suspense>
                    </div>}
                </div>
                <div ref={NewsViewerRef}>
                    {(current.includes("news_view") || old.includes("news_view")) && <div style={current.includes("news_view") ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <NewsViewer />
                        </Suspense>
                    </div>}
                </div>
                <div ref={NewsViewRef}>
                    {(current.includes("rotatorTest") || old.includes("rotatorTest")) && <div style={current.includes("rotatorTest") ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <NewsView />
                        </Suspense>
                    </div>}
                </div>
                <div ref={ProductsRef}>
                    {(current === "products" || old === "products") && <div style={current === "products" ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Products />
                        </Suspense>
                    </div>}
                </div>
                <div ref={AboutRef}>
                    {(current === "about" || old === "about") && <div style={current === "about" ? { opacity: "100%", transition: "0.35s" } : { opacity: 0, pointerEvents: 'none', transition: "0.35s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <About />
                        </Suspense>
                    </div>}
                </div>
                <div ref={ContactRef}>
                    {(current === "contact" || old === "contact") && <div style={current === "contact" ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Contact />
                        </Suspense>
                    </div>}
                </div>
                <div ref={ModerationRef}>
                    {(current === "moderation" || old === "moderation") && <div style={current === "moderation" ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Moderation />
                        </Suspense>
                    </div>}
                </div>
                <div ref={PageBuilderRef}>
                    {(current === "page_builder" || old === "page_builder") && <div style={current === "page_builder" ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <PageBuilder />
                        </Suspense>
                    </div>}
                </div>
                <div ref={ProductBuilderRef}>
                    {(current.includes("product_builder") || old.includes("product_builder")) && <div style={current.includes("product_builder") ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <ProductBuilder />
                        </Suspense>
                    </div>}
                </div>
                <div ref={NewsBuilderRef}>
                    {(current.includes("news_builder") || old.includes("news_builder")) && <div style={current.includes("news_builder") ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <NewsBuilder />
                        </Suspense>
                    </div>}
                </div>
                <div ref={ProductViewerRef}>
                    {(current.includes("product_page") || old.includes("product_page")) && <div style={current.includes("product_page") ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <ProductViewer />
                        </Suspense>
                    </div>}
                </div>
                <div ref={Order804Ref}>
                    {(current === "order804" || old === "order804") && <div style={current === "order804" ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Order804 />
                        </Suspense>
                    </div>}
                </div>
                <div ref={CustomersRef}>
                    {(current === "customers" || old === "customers") && <div style={current === "customers" ? { opacity: "100%", transition: "0.45s" } : { opacity: 0, pointerEvents: 'none', transition: "0.45s", position: 'absolute', top: size?.["height"] }}>
                        <Suspense fallback={<div>Загрузка...</div>}>
                            <Customers />
                        </Suspense>
                    </div>}
                </div>
                <div ref={FooterRef}>
                    <Footer />
                </div>

            </div>

        </div>
    );
}


const useSize = (target) => {
    const [size, setSize] = useState()

    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])
    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}
export default PageWrapper;
